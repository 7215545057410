import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import ContactPersonCard from "../../molecules/ContactPersonCard"
import DatoImage from "../DatoImage"
import * as styles from "./DatoContactPersons.module.scss"

const DatoContactPersons = (props: Queries.DatoContactPersonsFragment) => {
  // console.debug("Rendering DatoContactPersons", props)

  if (!props.contactPersons?.length) return null

  return (
    <div
      id={slugify(props.title)}
      className={styles.container}
      itemScope
      itemType="https://schema.org/OrganizationRole">
      <div className="layout-container">
        {props.title && <h2 itemProp="roleName">{props.title}</h2>}
        {props.text && <p itemProp="description">{props.text}</p>}
        <div className={styles.cards}>
          {props.contactPersons.map(person => {
            if (!person) return null
            const name = `${person.firstname} ${person.lastname}`
            return (
              <div
                key={person.id}
                className={[styles.card, props.contactPersons?.length === 1 && styles.bigCard]
                  .filter(Boolean)
                  .join(" ")}>
                <ContactPersonCard
                  image={
                    person.image ? <DatoImage {...person.image} alt={person.image?.alt || name || ""} /> : undefined
                  }
                  name={name}
                  role={person.roleRef?.role ?? undefined}
                  phone={person.phone ?? undefined}
                  email={person.email ?? undefined}
                  details={person.details ?? undefined}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default DatoContactPersons

export const query = graphql`
  fragment DatoContactPersons on DatoCmsContactPersonSet {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    text
    title
    contactPersons {
      id
      ...DatoContactPerson
    }
  }
`
