import React from "react"
import EmailLink from "../atoms/EmailLink"
import PhoneLink from "../atoms/PhoneLink"
import * as styles from "./ContactPersonCard.module.scss"

export type ContactPersonCardProps = {
  name: string
  role?: string
  phone?: string
  email?: string
  image?: React.ReactElement
  details?: string
}

const ContactPersonCard = (props: ContactPersonCardProps) => {
  // console.debug("Rendering ContactPersonCard", props)
  return (
    <div itemScope itemType="https://schema.org/Person" className={styles.container}>
      {props.image && <div className={styles.image}>{props.image}</div>}
      <div className={styles.textArea}>
        <h3 itemProp="name">{props.name}</h3>
        {props.role && <p itemProp="jobTitle">{props.role}</p>}
        {props.details && (
          <p itemProp="description" className={styles.details}>
            {props.details}
          </p>
        )}
        <p>
          {props.phone && <PhoneLink phone={props.phone} labelPrefix={"Tel."} />}
          <br />
          {props.email && <EmailLink email={props.email} labelPrefix={"Email"} />}
        </p>
      </div>
    </div>
  )
}

export default ContactPersonCard
